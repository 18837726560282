import TAX_AND_SHIPPING from '../../SITE_CONSTANTS/TAX_AND_SHIPPING'

class PriceObject {
  constructor (itemArray) {
    this.init(itemArray)
  }

  init(itemArray) {
    const itemTotal = this.getItemPriceTotal(itemArray)
    this.itemQuantity = this.getItemQuantity(itemArray)
    this.tax = (TAX_AND_SHIPPING.tax*this.itemQuantity)
    this.shipping = (TAX_AND_SHIPPING.shipping*this.itemQuantity)
    this.total = itemTotal + this.tax + this.shipping
    this.humanTax = PriceObject.humanPriceFormat(this.tax)
    this.humanShipping = PriceObject.humanPriceFormat(this.shipping)
    this.humanItemTotal = PriceObject.humanPriceFormat(itemTotal)
    this.humanTotal = PriceObject.humanPriceFormat(this.total)
  }

  static humanPriceFormat(price) {
    const priceInDollars = (price / 100)
    return `$${priceInDollars.toFixed(2)}`;
  }

  getItemQuantity(itemArray) {
    return itemArray.reduce((acc, item) => {
      acc = acc + item.quantity
      return acc
    }, 0)
  }

  getItemPriceTotal(itemArray) {
    return itemArray.reduce((acc, item) => {
      acc = acc + (item.price * item.quantity)
      return acc
    }, 0)
  }

  // getTax(total) {
  //   // Dynamic Tax Calculation
  //   return Math.ceil((total * TAX_AND_SHIPPING.tax))
  // }

}

export default PriceObject
